@import 'components/global.scss';

.app {

  padding: $scale2;
  min-height: 100%;
  --tw-bg-opacity: 1;
  background-color: $c-bg;

  @media (min-width: $mobile){

    padding-left: $scale7 + $scale1;

  }
}