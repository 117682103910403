body {
  color: #333;
}

/**  custom bootstrap - start */
.btn {
  border-radius: 25px;
  border-color: #ddd;
}

.btn:hover {
  border: solid 1px #bbb;
  background-color: #fff;
}

.container {
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
}

.btn:active {
  background-color: #f9f9f9;
  border: solid 1px #bbb;
  outline: none;
}

.btn:disabled {
  background-color: #f9f9f9;
  border: solid 1px #ddd;
  color: #bbb;
}

.btn:focus:active,
.btn:focus,
.btn:active {
  outline: none;
}

.move-today {
  padding: 0 16px;
  line-height: 30px;
}

.move-day {
  padding: 8px;
}

.render-range {
  padding-left: 12px;
  font-size: 19px;
  vertical-align: middle;
}

/** custom fontawesome */
.fa {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}

.total-items-price {
  font-size: 18px;
  text-align: right;
  margin-top: 50px;
  margin-right: 15px;
}

.edit-service-location {
  top: -58px;
  left: 150px;
  z-index: 99;
  position: relative;
  margin-bottom: -30px;
}

.edit-bill-to {
  top: -58px;
  left: 70px;
  z-index: 99;
  position: relative;
  margin-bottom: -30px;
}

.non-off-item-form fieldset div {
  width: 100%;
}