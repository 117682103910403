@import 'components/global.scss';

.date {
  
  position: relative;
  width: 100%;

  &:last-of-type {

    margin-bottom: 0;

  }

  > div:first-of-type {

    margin-top: -$scale5;

  }
  
  input {

    margin-bottom: 0;

  }

  span {

    width: 2em !important;

  }
}

.gravityCalendarStyles {
  color: 'white';
  position: absolute;
  top: -10em;
  left: -15em;
  width: 30em;
  height: 30em !important;
  min-height: 30em !important;
}